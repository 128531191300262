import styled from 'styled-components';

import { sizes } from '../../../../styles/variables';

export const ColorDiv = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.25em;
  font-weight: 600;
  background-color: ${(props) => props.color};
  width: 100%;
  height: 46px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-shadow: 1px 1px 2px #0005;

  :hover {
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  @media (max-width: ${sizes.smMax}) {
    height: 40px;
    position: relative;
  }
`;

export const StyledColorPicker = styled.div`
  background: transparent;
  width: 100%;
  height: 100%;
  position: relative;
  display: inline;

  @media (max-width: ${sizes.smMax}) {
    position: none;
    display: flex;
    flex-direction: column;
  }

`;

export const StyledTwitterPicker = styled.div`
  position: absolute;
  margin-top: 11px;
  top: 100%;
  left: 0;
  z-index: 10;
`;
