import React from 'react';

import Layout from '../../components/MenuLayout';
import { HospitalNew } from '../../components/Hospital/Edit/HospitalNew';

export default () => (
  <Layout type="new">
    <HospitalNew />
  </Layout>
);
