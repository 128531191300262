/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import { TwitterPicker } from 'react-color';
import { ColorDiv, StyledColorPicker, StyledTwitterPicker } from './styled/StyledColorPicker';

export const ColorPicker = ({ setColor, color, formProps = {} }) => {
  const { t } = useTranslation();
  const [showPicker, setShowPicker] = useState(false);
  const handleColorChange = ({ hex }) => setColor(hex, formProps);
  const ref = useRef();

  const closeColorPicker = () => {
    setShowPicker(false);
  };

  useOnclickOutside(ref, () => {
    closeColorPicker();
  });

  return (
    <StyledColorPicker ref={ref}>
      <ColorDiv color={color} onClick={() => setShowPicker(!showPicker)}>
        {t('click.choose.color')}
      </ColorDiv>
      {
        !!showPicker && (
          <StyledTwitterPicker>
            <TwitterPicker color={color} onChangeComplete={handleColorChange} />
          </StyledTwitterPicker>
        )
      }
    </StyledColorPicker>
  );
};
