import { useTranslation } from 'react-i18next';

export const getSelectList = (list) => list.map((item) => ({
  label: `ISO-3166-1-alpha2-${item}`,
  value: item,
}));

export const getLocaleSortedSelectList = (list) => {
  const { t } = useTranslation();
  const selectList = getSelectList(list);
  return ([
    { id: 0, value: '', label: 'select.a.nationality' },
    ...selectList.sort((a, b) => t(a.label).localeCompare(t(b.label))),
  ]);
};
