import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  errors: {
    paddingTop: 6,
    color: '#d72018',
    '& > p': {
      fontSize: '1.0714285714285714rem',
      lineHeight: 1.66,
    },
  },
}));

export const FormFieldErrorUI = ({ name, props, children }) => {
  const classes = useStyles();

  // eslint-disable-next-line react/destructuring-assignment
  const error = props && name && props.errors && props.errors[name];

  return (
    <Box className={classes.errors}>
      <Typography>
        {error}
        {error && children && ' - '}
        {children}
      </Typography>
    </Box>
  );
};
