/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ReactCrop from 'react-image-crop';
import { Thumb, ThumbInner, ThumbsContainer, PreviewImg } from './styled/StyledPreview';
import { StyledDropzone } from './styled/StyledDropzone';
import { Spacer } from '../styled/Spacer';
import { Row, Column } from '../styled/Groups';
import 'react-image-crop/dist/ReactCrop.css';

const defaultAccept = 'image/jpg, image/gif, image/png, image/jpeg';
const defaultAspect = '1 / 1';
const defaultMaxSize = 10000000; // bytes (10MB)

export function DropzoneWithCrop(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setFile, currentFileUrl, aspect, accept, maxSize, type } = props;
  const [upImg, setUpImg] = useState();
  const [imgRef, setImgRef] = useState(null);
  const [crop, setCrop] = useState({ aspect: aspect || defaultAspect });
  const [previewUrl, setPreviewUrl] = useState(currentFileUrl || null);

  function openViewer(images) {
    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  }

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(acceptedFiles[0]);
    }

    if (rejectedFiles && rejectedFiles.length > 0) {
      const currentMaxSize = maxSize || defaultMaxSize;
      const acceptedTypes = accept || defaultAccept;
      const acceptedTypesArray = acceptedTypes.split(',').map((item) => item.trim());
      if (!acceptedTypesArray.includes(rejectedFiles[0].type)) toast(t('invalid.file.type'), { className: 'toast-error' });
      else if (rejectedFiles[0].size > currentMaxSize) toast(t('invalid.file.size', { maxSize: currentMaxSize / 1000000 }), { className: 'toast-error' });
    }
  }, []);

  const onLoad = useCallback((img) => {
    setImgRef(img);
    let offset;
    if (img.width / aspect < img.height) {
      offset = 50 - ((img.width / aspect / 2) * 100 / img.height);
      setCrop({ unit: '%', width: 100, height: 100, y: offset, aspect });
    } else {
      offset = 50 - ((img.height * aspect / 2) * 100 / img.width);
      setCrop({ unit: '%', width: 100, height: 100, x: offset, aspect });
    }
    return false; // Return false when setting crop state in here.
  }, []);

  const createCropPreview = async (image, cropOptions, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropOptions.width;
    canvas.height = cropOptions.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      cropOptions.x * scaleX,
      cropOptions.y * scaleY,
      cropOptions.width * scaleX,
      cropOptions.height * scaleY,
      0,
      0,
      cropOptions.width,
      cropOptions.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        // eslint-disable-next-line no-param-reassign
        blob.name = fileName;
        window.URL.revokeObjectURL(previewUrl);
        setPreviewUrl(window.URL.createObjectURL(blob));
        setFile(blob);
      }, 'image/jpeg');
    });
  };

  const makeClientCrop = async (cropOptions) => {
    const fileName = type === 'logo' ? 'logo.jpeg' : 'profile.jpeg';
    if (imgRef && cropOptions.width && cropOptions.height) await createCropPreview(imgRef, cropOptions, fileName);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: accept || defaultAccept, multiple: false, maxSize: maxSize || defaultMaxSize });

  return (
    <>
      <Row>
        <Column>
          <StyledDropzone>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps({ id: 'profileImage', name: 'profileImage' })} />
              <span>
                <p>{t('drag.drop')}</p>
                <p>{t('or')}</p>
                <p><b>{t('click.choose.image.file')}</b></p>
              </span>
            </div>
          </StyledDropzone>
        </Column>
        <Spacer emptySibling />
        <Column>
          <ThumbsContainer>
            {previewUrl
              && (
                <Thumb>
                  <ThumbInner>
                    <PreviewImg
                      src={previewUrl}
                      alt="Profile image"
                      onClick={() => openViewer([previewUrl])}
                    />
                  </ThumbInner>
                </Thumb>
              )}
          </ThumbsContainer>
        </Column>

      </Row>
      <Spacer emptySibling />
      <Row className="justify-content-center">
        <ReactCrop src={upImg} crop={crop} minWidth="10" minHeight="10" onImageLoaded={onLoad} onChange={(newCrop) => setCrop(newCrop)} onComplete={makeClientCrop} />
      </Row>
    </>
  );
}
