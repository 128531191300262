import React, { useState, useContext } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { ThemeContext } from 'styled-components';
import Grid from '@material-ui/core/Grid';

import DialogActions from '@material-ui/core/DialogActions';
import { DropzoneWithCrop } from '../../Common/DropzoneWithCrop/DropzoneWithCrop';
import { Margin } from '../../Common/styled/Margins';
import { Form, Label } from '../../Common/styled/Form';
import { ColorPicker } from '../../Form/ColorPicker/ColorPicker';
import { countryList } from '../../../../locales/countries';
import { getLocaleSortedSelectList } from '../../../utils/SelectListUtils';
import { main } from '../../../themes/main';
import { TextFieldSelectUI } from '../../../componentsUI/TextFieldSelect';
import { TextFieldUI } from '../../../componentsUI/TextField';
import { AlertContainerUI, AlertUI } from '../../../componentsUI/Alert';
import { FormFieldErrorUI } from '../../../componentsUI/FormError';
import { ButtonUI } from '../../../componentsUI/Button';
import { canI } from '../../../casl/Can';
import Loading from '../../Common/Loading';
import { InnerSpanHTML } from '../../Common/InnerHTML';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  legacyLabel: {
    fontSize: '.85em',
    marginBottom: 6,
    display: 'block',
  },
  bautton: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
    border: `1px solid ${theme.palette.primary.main}30`,
  },
  fieldset: {
    border: 0,
    margin: 0,
    padding: 0,
    '& .MuiGrid-container': {
      '& > div > .MuiTextField-root': {
        width: '100%',
      },
      '& .MuiGrid-grid-xs-12:last-child': {
        height: 24,
        marginBottom: 16,
      },
    },
  },
}));

export const HospitalForm = ({
  hospital,
  submitLabel,
  handleSubmit,
  serverError = false,
  setFile,
  submitRef,
  submitting,
  lockSubmit = false,
}) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const classes = useStyles();
  const defaultColor = main.colors.primary;
  const [color, setColor] = useState(hospital ? hospital.mainColor || defaultColor : defaultColor);
  const selectCountryList = getLocaleSortedSelectList(countryList);

  const setMainColor = (colorValue, formProps) => {
    themeContext.setPrimaryColor(colorValue);
    const upperColorValue = colorValue.toUpperCase();
    setColor(upperColorValue);
    formProps.setFieldValue('mainColor', upperColorValue);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required.field')),
    streetAddress: Yup.string().required(t('required.field')),
    postalCode: Yup.string().required(t('required.field')),
    city: Yup.string().required(t('required.field')),
    country: Yup.string()
      .test('country', t('invalid.country'), (country) => countryList.includes(country)),
    mainColor: Yup.string()
      .required(t('required.field'))
      .matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, t('invalid.hex.color')),
  });
  const initialValues = {
    name: hospital ? hospital.name : '',
    streetAddress: hospital ? hospital.streetAddress : '',
    postalCode: hospital ? hospital.postalCode : '',
    country: hospital ? hospital.country.toLowerCase() : selectCountryList[0].value,
    city: hospital ? hospital.city : '',
    mainColor: color,
  };

  const goBack = () => window.history.back();

  const canEdit = hospital ? canI('edit', hospital) : true;
  const [errorTemplate, institutionName] = serverError && typeof serverError === 'string' && serverError.split('|');

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={submitRef}
    >
      {(props) => (
        <Form autoComplete="off" id="editHospital">
          <fieldset className={classes.fieldset} disabled={submitting}>
            <Grid container spacing={2}>
              {serverError && (
                <Grid item xs={12}>
                  <AlertUI severity="error" title="Error">
                    {errorTemplate && institutionName
                      ? <InnerSpanHTML content={t(errorTemplate, { institutionName })} />
                      : t(serverError)}
                  </AlertUI>
                </Grid>
              )}
              {!canEdit && (
                <AlertContainerUI>
                  <AlertUI severity="info" title={t('unauthorized')}>
                    {t('you.are.not.allowed.to.change.these.settings')}
                  </AlertUI>
                </AlertContainerUI>
              )}
              <Grid item xs={12} sm={4}>
                <TextFieldUI name="name" label={t('hospital.name')} props={props} classes={classes} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldUI name="streetAddress" label={t('street.address')} props={props} classes={classes} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldUI name="postalCode" label={t('postal.code')} props={props} classes={classes} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldUI name="city" label={t('city')} props={props} classes={classes} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldSelectUI
                  classes={classes}
                  name="country"
                  label={t('country')}
                  props={props}
                  options={selectCountryList}
                />
              </Grid>
            </Grid>
            <Margin mb-0 />
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Label htmlFor="mainColor" className={classes.legacyLabel}>{t('main.color')}</Label>
                <ColorPicker setColor={setMainColor} color={color} formProps={props} classes={classes} />
                <FormFieldErrorUI>{t(props.errors.mainColor)}</FormFieldErrorUI>
              </Grid>
            </Grid>
            <Margin mb-0 />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Label className={classes.legacyLabel}>{t('hospital.logo')}</Label>
                <DropzoneWithCrop
                  setFile={setFile}
                  currentFileUrl={hospital ? hospital.logo : null}
                  aspect={19 / 5}
                  type="logo"
                />
              </Grid>
              <Grid item xs={12}>
                {submitting && <Loading />}
              </Grid>
            </Grid>
            <DialogActions>
              <ButtonUI
                variant="outlined"
                disabled={submitting || lockSubmit}
                color="primary"
                onClick={goBack}
              >
                {t('back')}
              </ButtonUI>
              {canEdit && (
                <ButtonUI
                  variant="contained"
                  disabled={submitting || lockSubmit}
                  color="primary"
                  type="submit"
                >
                  {submitLabel}
                </ButtonUI>
              )}
            </DialogActions>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
