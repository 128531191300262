export const countryList = [
  'af',
  'ax',
  'al',
  'dz',
  'as',
  'ad',
  'ao',
  'ai',
  'aq',
  'ag',
  'ar',
  'am',
  'aw',
  'au',
  'at',
  'az',
  'bs',
  'bh',
  'bd',
  'bb',
  'by',
  'be',
  'bz',
  'bj',
  'bm',
  'bt',
  'bo',
  'ba',
  'bw',
  'bv',
  'br',
  'io',
  'bn',
  'bg',
  'bf',
  'bi',
  'kh',
  'cm',
  'ca',
  'cv',
  'ky',
  'cf',
  'td',
  'cl',
  'cn',
  'cx',
  'cc',
  'co',
  'km',
  'cg',
  'cd',
  'ck',
  'cr',
  'ci',
  'hr',
  'cu',
  'cy',
  'cz',
  'dk',
  'dj',
  'dm',
  'do',
  'ec',
  'eg',
  'sv',
  'gq',
  'er',
  'ee',
  'et',
  'fk',
  'fo',
  'fj',
  'fi',
  'fr',
  'gf',
  'pf',
  'tf',
  'ga',
  'gm',
  'ge',
  'de',
  'gh',
  'gi',
  'gr',
  'gl',
  'gd',
  'gp',
  'gu',
  'gt',
  'gg',
  'gn',
  'gw',
  'gy',
  'ht',
  'hm',
  'va',
  'hn',
  'hk',
  'hu',
  'is',
  'in',
  'id',
  'ir',
  'iq',
  'ie',
  'im',
  'il',
  'it',
  'jm',
  'jp',
  'je',
  'jo',
  'kz',
  'ke',
  'ki',
  'kp',
  'kr',
  'kw',
  'kg',
  'la',
  'lv',
  'lb',
  'ls',
  'lr',
  'ly',
  'li',
  'lt',
  'lu',
  'mo',
  'mk',
  'mg',
  'mw',
  'my',
  'mv',
  'ml',
  'mt',
  'mh',
  'mq',
  'mr',
  'mu',
  'yt',
  'mx',
  'fm',
  'md',
  'mc',
  'mn',
  'ms',
  'ma',
  'mz',
  'mm',
  'na',
  'nr',
  'np',
  'nl',
  'an',
  'nc',
  'nz',
  'ni',
  'ne',
  'ng',
  'nu',
  'nf',
  'mp',
  'no',
  'om',
  'pk',
  'pw',
  'ps',
  'pa',
  'pg',
  'py',
  'pe',
  'ph',
  'pn',
  'pl',
  'pt',
  'pr',
  'qa',
  're',
  'ro',
  'ru',
  'rw',
  'sh',
  'kn',
  'lc',
  'pm',
  'vc',
  'ws',
  'sm',
  'st',
  'sa',
  'sn',
  'cs',
  'sc',
  'sl',
  'sg',
  'sk',
  'si',
  'sb',
  'so',
  'za',
  'gs',
  'es',
  'lk',
  'sd',
  'sr',
  'sj',
  'sz',
  'se',
  'ch',
  'sy',
  'tw',
  'tj',
  'tz',
  'th',
  'tl',
  'tg',
  'tk',
  'to',
  'tt',
  'tn',
  'tr',
  'tm',
  'tc',
  'tv',
  'ug',
  'ua',
  'ae',
  'gb',
  'us',
  'um',
  'uy',
  'uz',
  'vu',
  've',
  'vn',
  'vg',
  'vi',
  'wf',
  'eh',
  'ye',
  'zm',
  'zw',
];

export const countryList3 = [
  'AFG',
  'ALA',
  'ALB',
  'DZA',
  'ASM',
  'AND',
  'AGO',
  'AIA',
  'ATA',
  'ATG',
  'ARG',
  'ARM',
  'ABW',
  'AUS',
  'AUT',
  'AZE',
  'BHS',
  'BHR',
  'BGD',
  'BRB',
  'BLR',
  'BEL',
  'BLZ',
  'BEN',
  'BMU',
  'BTN',
  'BOL',
  'BES',
  'BIH',
  'BWA',
  'BVT',
  'BRA',
  'IOT',
  'BRN',
  'BGR',
  'BFA',
  'BDI',
  'CPV',
  'KHM',
  'CMR',
  'CAN',
  'CYM',
  'CAF',
  'TCD',
  'CHL',
  'CHN',
  'CXR',
  'CCK',
  'COL',
  'COM',
  'COG',
  'COD',
  'COK',
  'CRI',
  'CIV',
  'HRV',
  'CUB',
  'CUW',
  'CYP',
  'CZE',
  'DNK',
  'DJI',
  'DMA',
  'DOM',
  'ECU',
  'EGY',
  'SLV',
  'GNQ',
  'ERI',
  'EST',
  'SWZ',
  'ETH',
  'FLK',
  'FRO',
  'FJI',
  'FIN',
  'FRA',
  'GUF',
  'PYF',
  'ATF',
  'GAB',
  'GMB',
  'GEO',
  'DEU',
  'GHA',
  'GIB',
  'GRC',
  'GRL',
  'GRD',
  'GLP',
  'GUM',
  'GTM',
  'GGY',
  'GIN',
  'GNB',
  'GUY',
  'HTI',
  'HMD',
  'VAT',
  'HND',
  'HKG',
  'HUN',
  'ISL',
  'IND',
  'IDN',
  'IRN',
  'IRQ',
  'IRL',
  'IMN',
  'ISR',
  'ITA',
  'JAM',
  'JPN',
  'JEY',
  'JOR',
  'KAZ',
  'KEN',
  'KIR',
  'PRK',
  'KOR',
  'KWT',
  'KGZ',
  'LAO',
  'LVA',
  'LBN',
  'LSO',
  'LBR',
  'LBY',
  'LIE',
  'LTU',
  'LUX',
  'MAC',
  'MDG',
  'MWI',
  'MYS',
  'MDV',
  'MLI',
  'MLT',
  'MHL',
  'MTQ',
  'MRT',
  'MUS',
  'MYT',
  'MEX',
  'FSM',
  'MDA',
  'MCO',
  'MNG',
  'MNE',
  'MSR',
  'MAR',
  'MOZ',
  'MMR',
  'NAM',
  'NRU',
  'NPL',
  'NLD',
  'NCL',
  'NZL',
  'NIC',
  'NER',
  'NGA',
  'NIU',
  'NFK',
  'MKD',
  'MNP',
  'NOR',
  'OMN',
  'PAK',
  'PLW',
  'PSE',
  'PAN',
  'PNG',
  'PRY',
  'PER',
  'PHL',
  'PCN',
  'POL',
  'PRT',
  'PRI',
  'QAT',
  'REU',
  'ROU',
  'RUS',
  'RWA',
  'BLM',
  'SHN',
  'KNA',
  'LCA',
  'MAF',
  'SPM',
  'VCT',
  'WSM',
  'SMR',
  'STP',
  'SAU',
  'SEN',
  'SRB',
  'SYC',
  'SLE',
  'SGP',
  'SXM',
  'SVK',
  'SVN',
  'SLB',
  'SOM',
  'ZAF',
  'SGS',
  'SSD',
  'ESP',
  'LKA',
  'SDN',
  'SUR',
  'SJM',
  'SWE',
  'CHE',
  'SYR',
  'TWN',
  'TJK',
  'TZA',
  'THA',
  'TLS',
  'TGO',
  'TKL',
  'TON',
  'TTO',
  'TUN',
  'TUR',
  'TKM',
  'TCA',
  'TUV',
  'UGA',
  'UKR',
  'ARE',
  'GBR',
  'USA',
  'UMI',
  'URY',
  'UZB',
  'VUT',
  'VEN',
  'VNM',
  'VGB',
  'VIR',
  'WLF',
  'ESH',
  'YEM',
  'ZMB',
  'ZWE',
];
