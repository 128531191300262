import styled from 'styled-components';

export const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Thumb = styled.div`
  display: inline-flex;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
`;

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0px;
  overflow: hidden;
  height: 100px;
`;

export const PreviewImg = styled.img`
  display: block;
  width: auto;
  height: 100px;
  cursor: pointer;
`;
